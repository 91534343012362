<template>
    <div class="login-page">
        <div class="page-left">
            <img class="img-icon" src="@/assets/new-logo.jpg"  @click="skipMysitefy"/>
        </div>
        <div class="page-right">
            <div @click="toggleDownload" class="down-load-btn">
                <img
                    src="@/assets/downloadBlack.png"
                    alt="download"
                    class="down-load-img"
                />
                <span class="down-text">下载领聚云APP，享更多掌上便捷</span>
                <div v-show="downloadShow" class="code-show">
                    <img src="@/assets/code.png" alt="code" />
                </div>
            </div>
            <div class="login-content-box">
                <div class="top-title">Mysitefy用户平台登录</div>
                <div class="login-content">
                    <!-- 二维码登录 -->
                    <div v-show="loginStyle === 'ewm'" class="ewmPic">
                        <div class="ewmImg">
                            <img
                                :src="`data:image/png;base64,${qrCodeData.qr_code}`"
                                alt=""
                            />
                            <div
                                v-if="checkQrCdoeStatusData.code === 20003"
                                class="expiredImg"
                                @click="getQrCode()"
                            >
                                <div class="text">二维码已过期</div>
                                <div class="refresh">
                                    <img
                                        src="@/assets/refresh-btn.png"
                                        alt=""
                                    />
                                    <p>刷新</p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="qrCodeTips"
                            class="qr-code-tips"
                            :style="{
                                color:
                                    checkQrCdoeStatusData.code === 20002
                                        ? '#4A82F4'
                                        : checkQrCdoeStatusData.code ===
                                              20004 ||
                                          checkQrCdoeStatusData.code ===
                                              20005 ||
                                          colorCode === 20004 ||
                                          colorCode === 20005
                                        ? '#EE2B2B'
                                        : '',
                            }"
                            v-html="qrCodeTips"
                        ></div>
                        <div v-else class="ewmText">
                            <p>请使用</p>
                            <p class="ljApp">“领聚云APP”</p>
                            <p>扫描上方二维码登录</p>
                        </div>
                    </div>
                    <!-- 手机框 -->
                    <div
                        v-show="loginStyle === 'mobile'"
                        class="number-content-box"
                    >
                        <div class="account">账号</div>
                        <div class="number-content">
                            <div class="input-content">
                                <el-form
                                    ref="form"
                                    @submit.native.prevent
                                    :rules="rules"
                                    :model="form"
                                >
                                    <el-form-item prop="mobile">
                                        <div
                                            v-if="isLogin && checkUsername"
                                            class="pass-inp"
                                        >
                                            <div class="userInfo">
                                                <img
                                                    :src="getToken.avatar"
                                                    alt=""
                                                />
                                                <p class="phone">
                                                    {{ getToken.username }}
                                                </p>
                                                <p
                                                    class="checkPhone"
                                                    @click="checkPhone"
                                                >
                                                    更换账号
                                                </p>
                                            </div>
                                        </div>
                                        <el-input
                                            v-else
                                            ref="mobile"
                                            v-model="form.mobile"
                                            maxlength="11"
                                            type="text"
                                            class="phone-inp"
                                            placeholder="请输入手机号"
                                        >
                                            <template slot="prepend"
                                                >+86</template
                                            >
                                        </el-input>
                                    </el-form-item>
                                    <div class="loginBtn">
                                        <div
                                            v-if="isLogin && checkUsername"
                                            type="primary"
                                            class="confirmBtn"
                                            @click="login"
                                        >
                                            登录
                                        </div>
                                        <div
                                            v-else
                                            type="primary"
                                            class="confirmBtn confirmNext"
                                            @click="confirmNext"
                                        >
                                            下一步
                                        </div>
                                    </div>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="login-go">
                    没有账号？<a :href="loginUrl" class="no-under">现在注册</a>
                </div>
                <div class="maskGroup" @click="ewmLogin">
                    <img
                        :src="require('../../assets/img/' + rightFootUrl)"
                        alt="二维码"
                    />
                </div>
            </div>

            <!-- 未注册 -->
            <el-dialog :visible.sync="noRegister" width="25%">
                <div class="noRegister">
                    <span class="no-register-text">该账号尚未注册</span>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="again">重新输入</el-button>
                        <el-button
                            type="primary"
                            class="registered"
                            @click="register"
                            >现在注册</el-button
                        >
                    </span>
                </div>
            </el-dialog>

            <div class="copyright">
                <div class="copy">
                    Copyright © 2016-2022 Mysitefy All rights reserved
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ajax from "../../js/ajax.js";
import global from "../../../global.js";
import { setStorage, getlocalStorage, rmStorage } from "@/utils/main.js";
export default {
    components: {},
    data() {
        return {
            form: {
                mobile: "",
            },
            qrCodeTips: "",
            downloadShow: false, // 是否显示下载二维码
            rules: {
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[1-9]+[0-9]*$/,
                        message: "请输入正确的手机号码",
                        trigger: "blur",
                    },
                ],
            },
            loginStyle: "mobile",
            rightFootUrl: "Mask Group.png",
            timer: null,
            qrCodeData: {},
            checkQrCdoeStatusData: {},
            checkUsername: true,
            colorCode: "",
            noRegister: false,
            needObj: {},
        };
    },
    watch: {
        $route: {
            handler(val) {
                this.form.mobile = val.query.fromLinkjoint;
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        getToken() {
            const token = getlocalStorage("token");
            return token ? this.$jwt_decode(token) : false;
        },
        isLogin() {
            if (this.getToken) {
                // 获取token的时间戳对比是否过期
                const ts = Math.round(new Date().getTime() / 1000).toString();
                const exp =
                    +this.getToken.exp > +ts &&
                    this.getToken.is_remember_login_status;
                return exp;
            } else {
                return false;
            }
        },
        loginUrl() {
            // switch (global.env) {
            //     case "dev": // dev
            //         return "http://192.168.1.196:6060/login/"; // 测试环境2端口6363
            //     case "test": // test
            //         // return "http://02testnodeclient.linkjoint.cn/"; // 测试环境2
            //         return "http://testnodeclient.linkjoint.cn/"; // 测试环境
            //     case "pro": // pro
            //         return "https://platform.mysitefy.com/";
            //     default:
            //         return "http://192.168.1.196:6060/login/"; // 本机环境默认开发
            // }
            switch (global.env) {
                case "dev": // dev
                    return "http://10.10.20.228:3030/register";
                case "test": // test
                    return "http://testregister.linkjoint.cn/register";
                case "pro": // pro
                    return "https://accounts.mysitefy.com/register";
                default:
                    return "http://192.168.2.101:8080/register"; // 本机环境默认开发
            }
        },
        welcomeUrl() {
            switch (global.env) {
                case "dev": // dev
                    return "http://10.10.20.228:6060/"; // 测试环境2端口6363
                case "test": // test
                    // return "http://02testnodeclient.linkjoint.cn/"; // 测试环境2
                    return "http://testnodeclient.linkjoint.cn/"; // 测试环境
                case "pro": // pro
                    return "https://platform.mysitefy.com/";
                default:
                    return "http://10.10.20.228:6060/"; // 本机环境默认开发
            }
        },
    },
    async created() {
        // 退出页跳过来
        if (this.$route.query.exit === "1") {
            rmStorage("token");
            rmStorage("user");
        } else if (this.$route.query.from) {
            this.needObj = JSON.parse(
                window.decodeURIComponent(window.atob(this.$route.query.from))
            );
            setStorage("token", this.needObj.token);
            const { data: user } = await ajax.get("/api/user/info");
            if (user.code === 200) {
                const userInfo = JSON.stringify(user.data);
                window.open(
                    `${this.welcomeUrl}welcome?token=${this.needObj.token}&user=${userInfo}`,
                    "_self"
                );
            }
        }
    },

    mounted() {},
    methods: {
        skipMysitefy() {
            window.location.href='https://www.mysitefy.com/'
        },
        toggleDownload() {
            this.downloadShow = !this.downloadShow;
        },
        // 切换手机号码
        checkPhone() {
            this.checkUsername = false;
        },
        async refreshUser() {
            const { data: user } = await ajax.get("/api/user/info");
            return JSON.stringify(user.data);
        },
        async login() {
            const { data } = await ajax.post(
                "/api/sso/account/login",
                {
                    is_silent_login: 1,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getlocalStorage("token")}`,
                    },
                }
            );
            if (data.code === 200) {
                setStorage("token", data.data);
                this.$message.success("登录成功");
				this.refreshUser().then((res) => {
					if(JSON.parse(res).tid){
						window.open(
						`${this.welcomeUrl}/welcome?token=${data.data}&user=${res}`,
						"_self"
						);
					}else{
						this.$router.push({ path: "/chooseTeam" });
					}
				});
            }
            if (data.code === 10003) {
                this.checkUsername = false;
            }
        },
        confirmNext() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.nextStep();
                } else {
                    return false;
                }
            });
        },
        // 验证手机号码是否注册
        async nextStep() {
            const { mobile } = this.form;
            const { data } = await ajax.post("/api/sso/isRegister", { mobile });
            if (data.code === 200) {
                const { avatar, is_set_password } = data.data;
                const accountInfo = {
                    mobile,
                    avatar,
                    is_set_password,
                };
                sessionStorage.setItem(
                    "accountInfo",
                    JSON.stringify(accountInfo)
                );
                this.$router.push("/logins");
            }
            if (data.code === 30004) {
                this.noRegister = true;
            }
        },
        // 注册
        register() {
            window.location.href = this.loginUrl;
        },
        // 重新输入
        again() {
            this.noRegister = false;
            this.$refs.mobile.focus();
        },
        // 二维码
        ewmLogin() {
            if (this.rightFootUrl === "Mask Group.png") {
                this.getQrCode();
            } else {
                if (this.timer) {
                    clearInterval(this.timer);
                }
            }
            this.loginStyle =
                this.rightFootUrl === "Group.png" ? "mobile" : "ewm";
            this.rightFootUrl =
                this.rightFootUrl === "Mask Group.png"
                    ? "Group.png"
                    : "Mask Group.png";
        },
        // 获取二维码
        async getQrCode() {
            const { data } = await ajax.get("/api/qr/code");
            if (data.code === 200) {
                this.qrCodeData = data.data;
                this.pollInterface(this.qrCodeData.uuid);
            }
        },
        // 轮询
        pollInterface(uuid) {
            this.timer = setInterval(() => {
                this.check(uuid);
            }, 1000);
        },
        // 二维码扫码状态查询
        async check(uuid) {
            const { data } = await ajax.get(`/api/check?uuid=${uuid}`);
            if (data.code === 200) {
                this.checkQrCdoeStatusData = data.data;
                if (data.data.data || data.data.code === 20000) {
                    setStorage("token", data.data.data);
                    clearInterval(this.timer);
                    const { data: user } = await ajax.get("/api/user/info");
                    if (user.code === 200) {
                        setStorage("user", user.data);
                        this.$notify({
                            title: "登录成功",
                            type: "succes",
                            message: "即将跳转至首页",
                            duration: 2000,
                            onClose: () => {
								this.refreshUser().then((res) => {
									if(JSON.parse(res).tid){
										window.open(
										`${this.welcomeUrl}/welcome?token=${data.data.data}&user=${res}`,
										"_self"
										);
									}else{
										this.$router.push({ path: "/chooseTeam" });
									}
                                });
                            },
                        });
                    }
                }
                if (data.data.code === 20002) {
                    this.qrCodeTips = "扫描成功<br />请在手机上点击确定以登录";
                    this.colorCode = "";
                }

                if (data.data.code === 20003) {
                    clearInterval(this.timer);
                    this.colorCode = "";
                }

                if (data.data.code === 20004) {
                    clearInterval(this.timer);
                    this.qrCodeTips =
                        "找不到可编辑网站<br />请确认账号是否正确或联系领聚客服";
                    this.getQrCode();
                    this.colorCode = 20004;
                }

                if (data.data.code === 20005) {
                    clearInterval(this.timer);
                    this.qrCodeTips =
                        "该账号无权<br />请确定账号是否正确或联系团主";
                    this.getQrCode();
                    this.colorCode = 20005;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.login-page {
    width: 100vw;
    min-height: 100vh;
    display: flex;

    .page-left {
        width: 53vw;
        box-sizing: border-box;
        background: url("../../assets/bg.png");
        background-repeat: repeat;
        background-size: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        .img-icon {
            width: 153px;
            height: 53px;
            cursor: pointer;
        }
    }

    .page-right {
        width: 47vw;
        height: 100vh;
        box-sizing: border-box;
        position: relative;
        background-color: #fff;
        .down-load-btn {
            position: absolute;
            cursor: pointer;
            top: 24px;
            right: 24px;
            width: 298px;
            height: 44px;
            border-radius: 2px;
            border: 1px solid #d9d9d9;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            .down-text {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
            }
            .down-load-img {
                width: 24px;
                height: 24px;
            }
            .code-show {
                position: absolute;
                width: 298px;
                height: 298px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 343px;
                background: #fff;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                &::before {
                    content: "";
                    position: absolute;
                    border-top: 20px solid transparent;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid #fff;
                    width: 0;
                    height: 0;
                    margin-top: -313px;
                    margin-left: -32px;
                }
                img {
                    width: 200px;
                }
            }
        }
        .login-content-box {
            width: 405px;
            padding-bottom: 50px;
            background: #ffffff;
            box-shadow: 0px 4px 20px 4px rgba(74, 130, 244, 0.09);
            border-radius: 20px;
            // border: 1px solid #d8e5fe;
            position: absolute;
            top: 43%;
            left: 50%;
            transform: translate(-50%, -40%);
            position: relative;
            .top-title {
                width: 211px;
                height: 48px;
                background: url("../../assets/top-title-bg.png") no-repeat top
                    center / 100% 100%;
                margin: 0 auto;
                position: absolute;
                left: 25%;
                margin-top: -25px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                line-height: 25px;
            }

            .login-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                .ewmPic {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 30px;
                    .qr-code-tips {
                        color: rgba(153, 153, 153, 1) 99;
                        text-align: center;
                        font-weight: 400;
                        font-family: "PingFangSC-Regular";
                    }
                    .ewmImg {
                        width: 180px;
                        height: 180px;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        .expiredImg {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background: rgba(255, 255, 255, 0.94);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;

                            .text {
                                font-size: 16px;
                                font-weight: 600;
                                color: #333333;
                                font-family: "PingFangSC-Regular";
                            }

                            .refresh {
                                margin-top: 15px;
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                color: rgba(74, 130, 244, 1);
                                font-size: 16px;
                                font-weight: 600;
                                img {
                                    width: 18px;
                                    height: 16px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    .ewmText {
                        display: flex;
                        font-size: 14px;
                        position: absolute;
                        top: 180px;
                        p {
                            color: #999;
                        }
                        .ljApp {
                            color: #ee2b2b;
                        }
                    }
                }
                .number-content-box {
                    margin-top: 72px;
                    .account {
                        color: #666666;
                    }
                    .number-content {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                        .input-content {
                            width: 100%;
                            flex: 1;
                            ::v-deep .el-input-group__prepend {
                                color: #000;
                            }
                        }
                    }
                }
                .phone-inp {
                    width: 334px;
                }
                .pass-inp {
                    height: 60px;
                    width: 334px;
                    border-radius: 4px;
                    border: 1px solid #efefef;
                    border-radius: 4px 4px 4px 4px;
                    overflow: hidden;
                    .userInfo {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        img {
                            width: 40px;
                            height: 40px;
                            margin-left: 12px;
                        }
                        .phone {
                            flex: 1;
                        }
                        .checkPhone {
                            cursor: pointer;
                            color: #4a82f4;
                            margin-right: 12px;
                        }
                    }
                    p {
                        font-size: 14px;
                        color: #666666;
                        margin-top: 6px;
                        height: 20px;
                        text-indent: 1em;
                        line-height: 20px;
                    }
                    ::v-deep .el-input__inner {
                        border: none;
                        height: 32px;
                    }
                    ::v-deep .el-input--suffix {
                        height: 32px;
                    }
                    ::v-deep .el-input__clear {
                        font-size: 20px;
                    }
                }
                // .nextText{
                //     width: 100%;
                //     margin-top: 43px;
                //     background: #4A82F4 ;
                //     border-color: #4A82F4;
                // }
                .loginBtn {
                    display: flex;
                    justify-content: center;
                    .confirmBtn {
                        color: #fff;
                        margin-top: 24px;
                        width: 316px;
                        height: 38px;
                        background: #4a82f4;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        cursor: pointer;
                    }
                    .confirmNext {
                        margin-top: 45px;
                    }
                }
            }
            .login-go {
                margin-top: 8px;
                text-align: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                .no-under {
                    color: #4a82f4;
                    text-decoration: none;
                }
            }
        }
        .noRegister {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 60px 0 70px;
            .no-register-text {
                font-size: 20px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 46px;
            }
            .registered {
                background-color: #4a82f4;
                border-color: #4a82f4;
            }
        }
        .copyright {
            position: absolute;
            width: 100%;
            bottom: 30px;

            .text {
                text-align: center;
            }

            .copy {
                text-align: center;
            }
        }
        .maskGroup {
            position: absolute;
            height: 54px;
            right: 5px;
            bottom: 5px;
            img {
                height: 54px;
                width: 54px;
            }
        }
    }
}
::v-deep .el-input-group__prepend {
    background-color: #ffff;
}
::v-deep .el-form-item__error {
    line-height: 19px;
}
</style>
